import { http, authHeader } from '@/http-commons';

class ProductoListaPrecioDataService {
    show(idLista) {
        return http.get(`api/v1/productoListaPrecio/show/${idLista}`, { headers: authHeader() });
    }
    cancel(id) {
        return http.delete(`api/v1/productoListaPrecio/cancel/${id}`, { headers: authHeader() });
    }
    findAll() {
        return http.get('api/v1/productoListaPrecio/findAllProductoListaPrecio', { headers: authHeader() });
    }
    findByClienteId(clientId){
        return http.get(`api/v1/productoListaPrecio/getProductoPrecioCliente/${clientId}`, { headers: authHeader() });
    }
    listTable(params) {
        return http.get('api/v1/productoListaPrecio/table', { params, headers: authHeader() });
    }
    exportProductPriceByDateAndClientId(params) {
        return http.get('api/v1/productoListaPrecio/downloadProductPriceExcel', { params, headers: authHeader(), responseType: 'blob' });
    }
}
export default new ProductoListaPrecioDataService();
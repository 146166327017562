<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card class="elevation-1">
            <v-toolbar dark color="primary">
                <v-btn icon dark @click.native="handleModal">
                <v-icon>fas fa-times-circle</v-icon>
                </v-btn>
                <v-toolbar-title>Generar reporte de lista de precios</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
            <v-row>
            <!-- <v-col cols="6">
                <SingleCalendar refElement="startDate" title="Fecha inicio" :input-date.sync="startDate" />
            </v-col>
            <v-col cols="6">
                <SingleCalendar refElement="endDate" title="Fecha fin" :input-date.sync="endDate" :min-date="startDate" />
            </v-col> -->
                <v-col cols="12" class="mt-5">
                    <v-autocomplete :items="products" item-text="nombre" item-value="id"
                    prepend-inner-icon="fas fa-cube" hint="Selecciona un producto."
                    v-model="productId" label="Productos" required outlined clearable >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12">
                    <v-autocomplete :items="clients" item-text="nombreComercial" item-value="idCliente"
                    prepend-inner-icon="fas fa-users" hint="Selecciona un cliente opcional."
                    v-model="clientId" label="Clientes" required outlined clearable>
                    </v-autocomplete>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click.native="downloadExcelProductPrice">Descargar Excel</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import SingleCalendar from '@/components/shared/SingleCalendar.vue';
import ProductoListaPrecioDataService from '@/service/producto/ProductoListaPrecioDataService';
import { getDateLocaleNatural, getToday } from '@/utils/DateUtil';

export default {
    name: 'ExcelExportetProductPrice',
    props: {
        dialog: { type: Boolean, default: false },
        handleModal: { type: Function },
        products: { type: Array, required: true, default: [] },
        clients: { type: Array, required: true, default: [] },
        isAdmin: { type: Boolean, default: false }
    },
    components: { SingleCalendar },
    data() {
        return {
            startDate: getToday().toFormat('yyyy-MM-dd'),
            endDate: getToday().toFormat('yyyy-MM-dd'),
            productId: null,
            clientId: null,
            showDetail: false
        }
    },
    methods: {
        downloadExcelProductPrice() {
            const startDate = getDateLocaleNatural(this.startDate);
            const endDate = getDateLocaleNatural(this.endDate);
            this.$swal.fire({
                title: 'Quieres continuar con la descarga del reporte de lista de precios?',
                text: "El tiempo de descarga puede variar dependiendo de la cantidad de registros!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, descargar!',
                cancelButtonText: 'Cancelar',
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    const params = { startDate, endDate, productId: this.productId, clientId: this.clientId };
                    try {
                        return await ProductoListaPrecioDataService.exportProductPriceByDateAndClientId(params);
                    } catch (error) {
                        this.$swal.showValidationMessage(`Petición fallida: ${error}`);                        
                    }
                },
                allowOutsideClick: () => !this.$swal.isLoading()
            }).then( result => {
                if (result.isConfirmed) {
                    if(result.value.status === 204) {
                        this.$swal.fire({ icon: 'info', title: 'Reporte', text: 'No hay registros para descargar', showConfirmButton: false, timer: 1500 });
                    } else {
                        const { data } = result.value;
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'reporte-lista-de-precios.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        this.$swal.fire({ icon: 'success', title: 'Reporte', text: 'Reporte descargado', showConfirmButton: false, timer: 1500 });
                    }
                }
            }).catch(err => {
                this.$swal.fire({ position: 'top-end', icon: 'error', html: 'Error al obtener los datos' + err, showConfirmButton: false, timer: 3500 });
            });
        }
    }
}
</script>
<template>
    <v-dialog v-model="dialog" persistent transition="scale-transition" fullscreen>
        <v-card class="elevation-1">
            <ValidationObserver ref="refListaEdit" v-slot="{ invalid, validated }" :immediate="true">
                <v-form name="form" @submit.prevent="updateLista">
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="handleModal">
                            <v-icon>fas fa-times-circle</v-icon>
                        </v-btn>
                        <v-toolbar-title>Editar lista de precio</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn class="primary" type="submit" :disabled="invalid || !validated || hasListaPrecioNullOrZero ">
                                <v-icon class="ma-2" dense large left>fas fa-save</v-icon>Actualizar lista
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text>
                        <v-container fluid>
                            <v-row wrap>
                                <v-col cols="4">
                                    <ValidationProvider rules="required" v-slot="{errors, valid }" :immediate="true">
                                        <v-text-field v-model="listaPrice.clave" label="Palabra clave de posterior busqueda.*"
                                        filled dense hint="Campo requerido.." persistent-hint prepend-icon="fas fa-key"
                                        :error-messages="errors" :success="valid" required>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="8">
                                    <ValidationProvider rules="required" v-slot="{errors, valid }" :immediate="true">
                                        <v-textarea label="Nota adicional*" rows="1" filled dense
                                        hint="Comentario adicional para esta lista de precio" prepend-icon="fas fa-sticky-note"
                                        persistent-hint v-model="listaPrice.nota" counter :error-messages="errors" :success="valid">
                                        </v-textarea>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <v-row>
                                <addListaPrecio ref="addListaPrecio" :cart.sync="dialog"
                                :products="products" mode="edit"
                                @addProductPrice="addProductPrice"
                                :listProductPrice.sync="listProductPrice">
                                </addListaPrecio>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-form>
            </ValidationObserver>
        </v-card>
    </v-dialog>
</template>
<script>
import ListaPrecioDataService from "@/service/producto/ListaPrecioDataService";
import ProductoListaPrecioDataService from "@/service/producto/ProductoListaPrecioDataService";
import addListaPrecio from "./addListaPrice.vue";
export default {
    name: "EditarListaPrecio",
    components: { addListaPrecio },
    props: {
        dialog : { type: Boolean, default: false },
        handleModal: { type: Function },
        initialize: { type: Function },
        lista: { type: Object, default: () => {} },
        products: { type: Array, default: () => [] },
    },
    created(){
        this.getListaPrecio(this.lista.id);
    },
    data(){
        return {
            listaPrice: this.lista,
            listProductPrice: [],
        }
    },
    methods: {
        getListaPrecio(idLista){
            ProductoListaPrecioDataService.show(idLista).then(resultProdlistPrice => {
                this.listProductPrice = resultProdlistPrice.data;
            });
        },
        addProductPrice(productPrice){
            this.listaPrice.productoPrice = productPrice;
        },
        updateLista(){
            if(this.hasListaPrecioNullOrZero){
                this.$swal.fire({ icon: 'warning', text: 'La lista de precio no puede contener precios en cero o vacíos', showConfirmButton: false, timer: 2000 });
                return;
            }
            this.$refs.refListaEdit.validate().then( isValid =>{
                if( isValid ){
                    ListaPrecioDataService.update(this.listaPrice).then( result => {
                        this.handleModal();
                        this.initialize();
                        this.$swal.fire({icon: 'success', text: result.data.message, title: 'Actualización lista de precio', showConfirmButton: false, timer: 1500 });
                    }).catch( err => {
                        this.$swal.fire({ icon: 'error', html: err.response.data, showConfirmButton: false, timer: 3000 });
                    })
                }
            }).catch( error => {
                this.$swal.fire({ icon: 'error', html: error, showConfirmButton: false, timer: 3000 });
            })
        }
    },
    computed: {
        hasListaPrecioNullOrZero(){
            if(this.listaPrice.productoPrice == undefined ) return true;
            const listWithZero = this.listaPrice.productoPrice.filter( item => item.precioVenta === 0 || item.precioVenta === null || item.precioVenta === undefined || item.precioVenta === '');
            return listWithZero.length > 0;
        }
    }
}
</script>
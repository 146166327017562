<template>
    <v-row>
        <v-col cols="12" sm="12" md="12">
            <v-card class="elevation-4">
                <v-card-title>
                    <v-toolbar flat>
                        <v-btn small dark color="cyan darken-4" @click="dialog = true">
                            <v-icon left>fas fa-plus-square</v-icon>Nuevo lista de precio
                        </v-btn>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-btn small dark color="cyan darken-4"
                            @click="mostrarInactivos = !mostrarInactivos">
                            <v-icon left>fas fa-tag</v-icon>{{ mostrarInactivos ? 'Listas Activas' : 'Listas Inactivas' }}
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-spacer></v-spacer>
                    <v-col cols="12" md="6" sm="4" class="d-flex justify-end">
                        <v-text-field v-model="search" append-icon="fas fa-search" label="Buscar precio" single-inline hide-details @keydown.enter="searchListaPrecio">
                        </v-text-field>
                        <v-btn icon class="mt-4 ml-2">
                            <v-icon dense>fas fa-sync</v-icon>
                        </v-btn>
                        <v-tooltip left color="success" >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon class="mt-4 ml-2" color="success" v-bind="attrs" v-on="on" dark @click="handleExcelExport">
                                    <v-icon dark left>fas fa-file-excel</v-icon>
                                </v-btn>
                            </template>
                            <span>Reporte lista de precios</span>
                        </v-tooltip>
                        <Columns :headers.sync="headers"></Columns>
                    </v-col>
                </v-card-title>
                <v-data-table dense :headers="filteredHeaders" :items="filterByStatus" class="elevation-1"
                :loading="loading" :options.sync="options"
                :footer-props="{ 'items-per-page-options': [ 25, 50, 100 ] }"
                :server-items-length="totalListaPrecio">
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon small class="mr-2" @click="show(item)" v-if="!item.eliminado">fas fa-edit</v-icon>
                        <v-icon small class="mr-2" @click="remove(item)" v-if="!item.eliminado" color="danger">fas fa-trash-alt</v-icon>
                        <v-icon small class="mr-2" @click="deleteDb(item)" v-if="item.eliminado" color="danger">fas fa-trash-alt</v-icon>

                        <v-tooltip top color="error">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon small class="mr-2" @click="activarLista(item)"
                                v-bind="attrs" v-on="on"
                                v-if="item.eliminado">fas fa-unlock</v-icon>
                            </template>
                            <span>Activar lista</span>
                        </v-tooltip>

                    </template>

                    <template v-slot:[`item.eliminado`]="{ item }">
                        <v-chip :color="colorStatus(item.eliminado)" label text-color="white" x-small>
                            <v-icon x-small left color="white">fas fa-tag</v-icon>
                            {{ item.eliminado ? 'Inactivo' : 'Activo' }}
                        </v-chip>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
        <NuevaListaPrecio :dialog.sync="dialog" :initialize.sync="initTableListaPrecio" :handleModal="handleModal" v-if="dialog"/>
        <EditarListaPrecio :dialog.sync="dialogEdit" :initialize.sync="initTableListaPrecio"
        :products.sync="products" :lista.sync="infoLista"
        :handleModal="handleModalEdit" v-if="dialogEdit"/>
        <ExcelExporterProductPrice :dialog.sync="dialogExcelExport" :handleModal="handleExcelExport"
        :products.sync="products" :clients.sync="clients" :is-admin="isAdmin"
        v-if="dialogExcelExport"/>
    </v-row>
</template>
<script>
import ListaPrecioDataService from "@/service/producto/ListaPrecioDataService";
import ProductoDataService from "@/service/producto/ProductoDataService";
import NuevaListaPrecio from "./NuevaListaPrecio.vue";
import EditarListaPrecio from "./EditarListaPrecio.vue";
import { getParamsPagination } from "@/utils/pagination";
import ExcelExporterProductPrice from "./ExcelExporterProductPrice.vue";
import UsuarioDataService from "@/service/usuario/usuarioDataService";
import ClienteDataService from "@/service/cliente/clienteDataService";
import Columns from "@/components/shared/Columns.vue";

export default {
    name: 'ListaPrecio',
    components: { NuevaListaPrecio, EditarListaPrecio, ExcelExporterProductPrice, Columns },
    created(){
        this.initTableListaPrecio();
        this.initProducts();
        this.initClients();
    },
    data(){
        return {
            listaPrecio: [],
            products: [],
            clients: [],
            options: {},
            totalListaPrecio: 0,
            loading: false,
            search: "",
            mostrarInactivos: false,
            dialog: false,
            dialogEdit: false,
            infoLista: [],
            headers: [
                { text: "Acciones", value: "actions", sortable: false, groupable: false, show: true },
                { text: "ID", align: " d-none", sortable: false, value: "id", visible: false, groupable: false, show: false },
                { text: "Clave", value: "clave", sortable: false, groupable: false, show: true },
                { text: "Nota", value: "nota", sortable: false, groupable: false, show: true },
                { text: 'Estatus', value: 'eliminado', sortable: false, groupable: false, show: true },
            ],
            dialogExcelExport: false
        }
    },
    methods: {
        searchListaPrecio() {
            this.options.page = 1;
            this.initTableListaPrecio(this.options);
        },
        initTableListaPrecio( options = {} ) {
            this.loading = true;
            const params = getParamsPagination({ options, search: this.search });
            ListaPrecioDataService.listTable(params).then( res => {
                const { rows, totalElements } = res.data;
                this.listaPrecio = rows;
                this.totalListaPrecio = totalElements;
            }).catch( err => {
                this.$swal.fire({ icon: 'error', title: 'Oops...', html: err.response.data })
            }).finally( () => {
                this.loading = false;
            })
        },
        show ( item ) {
            ListaPrecioDataService.show(item.id).then( res => {
                this.infoLista = res.data;
                this.dialogEdit = true;
            }).catch( err => {
                this.$swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data })
            });
        },
        remove( item ) {
            this.$swal({
                title: "Estas seguro?",
                text: "Quieres desactivar la lista?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si, desactivar!",
                cancelButtonText: "Cancelar"
            }).then(result => {
                if (result.isConfirmed) {
                    ListaPrecioDataService.cancel(item.id).then( resp => {
                        this.initTableListaPrecio();
                        this.$swal.fire({ icon: 'success', html: `Lista de precio <b>${resp.data.clave}</b> cancelada`, title: 'Lista cancelada!', showConfirmButton: false, timer: 2500 })
                    }).catch( err => {
                        this.$swal.fire({  icon: 'error', title: 'Cancelación fallida', text: err.response.data, showConfirmButton: false, timer: 2500 })
                    });
                }
            });
        },
        deleteDb( item ) {
            this.$swal({
                title: "Estas seguro?",
                text: "No se podra revertir la decision!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si, eliminar!",
                cancelButtonText: "Cancelar"
            }).then(result => {
                if (result.isConfirmed) {
                    ListaPrecioDataService.deleteDb(item.id).then( resp => {
                        if ( resp.status === 200) {
                            this.initTableListaPrecio();
                            this.$swal.fire({ icon: 'success', text: resp.data.message, title: 'Lista cancelada!', showConfirmButton: false, timer: 2500 })
                        }
                        if ( resp.status === 400 || resp.status === 404 ){
                            this.$swal.fire({ icon: 'error', title: 'Oops...', text: resp.data.message, showConfirmButton: false, timer: 2500 })
                        }
                    }).catch( err => {
                        this.$swal.fire({  icon: 'error', title: 'Oops...', text: err.response.data.message, showConfirmButton: false, timer: 2500 })
                    });
                }
            });
        },
        activarLista( item ){
            this.$swal.fire({
                title: "Estas seguro?",
                text: "No se podra revertir la decision!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si, activar!",
                cancelButtonText: "Cancelar"
            }).then(result => {
                if (result.isConfirmed) {
                    ListaPrecioDataService.activate(item.id).then( resp => {
                        if ( resp.status === 200) {
                            this.initTableListaPrecio();
                            this.$swal.fire({ icon: 'success', text: resp.data.message, title: 'Lista activada!', showConfirmButton: false, timer: 2500 })
                        }
                        if ( resp.status === 400 || resp.status === 404 ){
                            this.$swal.fire({ icon: 'error', title: 'Oops...', text: resp.data.message, showConfirmButton: false, timer: 2500 })
                        }
                    }).catch( err => {
                        this.$swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message, showConfirmButton: false, timer: 2500 })
                    });
                }
            });
        },
        colorStatus( status ){ return status ? 'red darken-1' : 'teal darken-2' },
        handleModal(){ this.dialog = !this.dialog; },
        handleModalEdit(){ this.dialogEdit = !this.dialogEdit; },
        initProducts(){
            ProductoDataService.findAll().then( resp => { this.products = resp.data; }) || [];
        },
        initClients(){
            ClienteDataService.getAll().then( resp => { this.clients = resp.data; }) || [];
        },
        handleExcelExport(){
            this.dialogExcelExport = !this.dialogExcelExport;
        }
    },
    watch: {
        options: {
            handler( val, oldVal ) {
                if (val.page !== oldVal.page || val.itemsPerPage !== oldVal.itemsPerPage) {
                    this.initTableListaPrecio(val);
                }
            }, deep: true
        }
    },
    computed: {
        filterByStatus(){
            return this.mostrarInactivos
            ? this.listaPrecio.filter(li => li.eliminado)
            : this.listaPrecio.filter(li => !li.eliminado)
        },
        isAdmin(){ return UsuarioDataService.getUserRoles().includes('ROLE_ADMIN'); },
        filteredHeaders() { return this.headers.filter(h => h.show); }
    }
}
</script>